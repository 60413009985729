<template>
  <div class="container container-secciones">
    <img class="logo-seccion" :src="seccion.logo" :alt="seccion.name">
    <h1 class="titulo">{{ seccion.name }}</h1>
    <p class="descripcion">{{ seccion.description }}</p>
    <div>
      <PubSubseccionLista :seccion="this.$route.params.id" />
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import PubSubseccionLista from "../components/PubSubseccionLista";

export default {
  name: "Seccion",
  components: {
    PubSubseccionLista,
  },
  data() {
    return {
      key: "",
      seccion: {},
    };
  },
  created() {
    const ref = firebase
      .firestore()
      .collection("secciones")
      .doc(this.$route.params.id);
    ref.get().then((doc) => {
      if (doc.exists) {
        this.key = doc.id;
        this.seccion = doc.data();
      } else {
        alert("No existe la sección!");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";

.container-secciones {
  padding-top: 6rem;
  @include media-breakpoint-up(md) {
    padding-top: 8rem;
  }
}

.logo-seccion {
  width: 100px;
  display: block;
  margin: auto;
  padding: 0.5rem;
  // background-color: $color-gris;
  // border-radius: 5%;
}
.titulo {
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  margin: 0.5rem;
  @include media-breakpoint-up(md) {
    font-size: 1.8rem;
  }
}
.descripcion {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.75rem;
  color: $color-text;
  @include media-breakpoint-up(md) {
    font-size: 0.9rem;
  }
}
</style>
